import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// require('./router/guard')
import store from './store'
// 引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// 引入swiper
//引入全局配置文件
import sysConfig from '@/utils/config'
//引入全局样式文件
import '@/assets/css/common.scss'
import '@/assets/css/reset.scss'
import '@/assets/css/custom.scss'
import '@/assets/css/utility.scss'
//引入svg-icon组件
import SvgIcon from '@/components/svgicon'
//引入获取站点信息函数
import { getSiteInfo } from '@/api/system'
import { setLocalStorage, getLocalStorage } from '@/utils/auth'

//查询用户的首页模板，如果没有，则使用默认模板
let templateName = 'index'
if(getLocalStorage("mer")){
	//动态的首页路由,更换模板时
	const indexRouter = {
		path: '/',
		name: 'Index',
		component: () => import('@/views/index/' + templateName),
		meta: {
			title: '首页'
		}
	};
	router.addRoute(indexRouter);
	const app = createApp(App);
	app.config.globalProperties.$config = sysConfig //注册全局变量
	app.use(store)
	app.use(router)
	app.use(ElementPlus, { size: 'medium' })
	app.component('SvgIcon', SvgIcon)
	app.mount('#app');
}else {
	//这里必须使用jquery发起同步的ajax请求,不能使用axios
	$.ajax({
		url: sysConfig.baseURL + '/index/getMer',
		type: 'post',
		beforeSend(request) {
			request.setRequestHeader("Content-Type", "application/json");
		},
		dataType: 'json',
		async: false,
		success(res) {
			if (res.code == 1) {
				setLocalStorage('mer', res.data.mer)
				//获取站点信息
				//将站点信息存储进去
				setLocalStorage('siteInfo', res.data.siteinfo);
				setLocalStorage('showSiteInfo', res.data.logo);

				//动态的首页路由,更换模板时
				const indexRouter = {
					path: '/',
					name: 'Index',
					component: () => import('@/views/index/' + templateName),
					meta: {
						title: '首页'
					}
				};
				router.addRoute(indexRouter);

				const app = createApp(App);
				app.config.globalProperties.$config = sysConfig //注册全局变量
				app.use(store)
				app.use(router)
				app.use(ElementPlus, { size: 'medium' })
				app.component('SvgIcon', SvgIcon)
				app.mount('#app')

			} else {
				console.log(res.data);
				return false;
			}
		}
	})
}

