import { createStore } from 'vuex'
import { getLocalStorage, removeLocalStorage,setLocalStorage } from "@/utils/auth";
export default createStore({
  state: {
    token: getLocalStorage('token') || '',
    userInfo: getLocalStorage('userInfo') || null,
    siteInfo: getLocalStorage('siteInfo') || null
  },
  mutations: {
    //赋值token
    setToken(state, token) {
      setLocalStorage('token', token)
      state.token = token
    },
    //赋值用户信息
    setUserInfo(state, userData) {
      setLocalStorage('userInfo', userData)
      state.userInfo = userData
    },
    //赋值网站信息
    setSiteInfo(state, siteData) {
      setLocalStorage('siteInfo', siteData)
      state.siteInfo = siteData
    }
  },
  actions: {
  },
  modules: {
  }
})
