import CryptoJS from "crypto-js"

const secretKey = 'sowaykey2021'

export function setLocalStorage(key, value) {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  localStorage.setItem(key, encData);
}

export function getLocalStorage(key) {
  if (localStorage.getItem(key)) {
    let decData = CryptoJS.enc.Base64.parse(localStorage.getItem(key)).toString(CryptoJS.enc.Utf8);
    let decJson = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decJson);
  }
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key);
}

export function setSessionStorage(key, value) {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  sessionStorage.setItem(key, encData);
}

export function getSessionStorage(key) {
  if (sessionStorage.getItem(key)) {
    let decData = CryptoJS.enc.Base64.parse(sessionStorage.getItem(key)).toString(CryptoJS.enc.Utf8);
    let decJson = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decJson);
  }
}

export function removeSessionStorage(key) {
  return sessionStorage.removeItem(key);
}
