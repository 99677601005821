import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {getLocalStorage,removeLocalStorage} from "@/utils/auth";
import sysConfig from '@/utils/config'

import { ElNotification } from 'element-plus';

const service = axios.create({
  baseURL: sysConfig.baseURL, 
  timeout: 50000,
  // headers: {'Content-Type': 'text/plain;charset=UTF-8'}
});

//请求拦截
service.interceptors.request.use(
  config => {
    config.headers['mer'] = getLocalStorage('mer')
    config.headers['token'] = getLocalStorage('token') || ''
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

//响应拦截
service.interceptors.response.use(
  response => {
    let res = response.data
    if(res.code==2){
      ElNotification({
        type:'error',
      	title: '登录失效',
      	message: '请重新登录!',
      	duration:1500,
      	showClose:false
      })
      //store.commit('setToken',null)
      //store.commit('setUserInfo',null)
      removeLocalStorage('userInfo')
      removeLocalStorage('token')
      router.replace({
        path:'/'
      })
    }
    return res;
  },
  error => {
    return Promise.reject(error)
  }
)
export default service
