import md5 from 'js-md5';
export default {
  // baseURL: 'http://api.hbsoway.org/api',//本地测试,
  // baseURL: 'http://sc.hbsoway.com/api',//测试站,
  //baseURL: 'http://api.csciunion.com/api',//正式站
  //baseURL: 'http://turing.mosenedu.com/api', //临时
  baseURL: 'https://api.hbsoway.com/api', //正式站
  //favicon:'favicon.ico', //网页小图标
  onlinePay:true, //在线支付
  //buttonType:'success', //统一按钮的样式
  buttonType: 'primary', //统一按钮的样式
}