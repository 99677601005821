import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { getLocalStorage,setLocalStorage,setSessionStorage} from '@/utils/auth'
import sysConfig from '@/utils/config'

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: () => import('@/views/index/index'),
  //   meta:{
  //     title:'首页'
  //   }
  // },
  {
    path: '/about/:type',
    name: 'About',
    component: () => import('@/views/about/index'),
    meta: {
      title: '关于我们'
    },
    // props:true
  },
  {
    path: '/course',
    name: 'Course',
    component: () => import('@/views/course/index'),
    meta: {
      title: '课程中心'
    }
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/live/index'),
    meta: {
      title: '在线直播'
    }
  },
  {
    path: '/course/:pid/:id',
    name: 'CourseList',
    component: () => import('@/views/course/index'),
    props:true,
    meta: {
      title: '课程'
    }
  },
  {
    path: '/coursedetail/:specid/:comboclassid/:comboid',
    name: 'CourseDetail',
    component: () => import('@/views/course/CourseDetail'),
    props: true,
    meta: {
      title: '课程详情'
    }
  },
  {
    path: '/courseplayer/:comboid/:gradeid:/:sectionid',
    name: 'CoursePlayer',
    props: true,
    component: () => import('@/views/course/CoursePlayer'),
    meta: {
      title: '课程播放'
    }
  },
  {
    path: '/exam',
    name: 'Exam',
    component: () => import('@/views/exam/index'),
    meta: {
      title: '在线题库'
    }
  },
  {
    path: '/examlist',
    name: 'ExamList',
    component: () => import('@/views/exam/ExamList'),
    meta: {
      title: '题库列表'
    }
  },
  {
    path: '/secretdetail',
    name: 'SecretDetail',
    component: () => import('@/views/exam/SecretDetail'),
    meta: {
      title: '密押卷详情'
    }
  },
  {
    path: '/questionresult',
    name: 'QuestionResult',
    component: () => import('@/views/exam/QuestionResult'),
    meta: {
      title: '答题结果'
    }
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('@/views/exam/Question'),
    meta: {
      title: '答题'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/news/index'),
    meta: {
      title: '资讯'
    }
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: () => import('@/views/teacher/index'),
    meta: {
      title: '行业导师'
    }
  },
  {
    path: '/teacherdetail/:id',
    name: 'TeacherDetail',
    component: () => import('@/views/teacher/TeacherDetail'),
    meta: {
      title: '导师详情'
    }
  },
  {
    path: '/app',
    name: 'App',
    component: () => import('@/views/app/index'),
    meta: {
      title: 'App下载'
    }
  },
  {
    path: '/search/:keyword',
    name: 'Search',
    component: () => import('@/views/search/index'),
    meta:{
      title:'搜索'
    }
  },
  {
    path: '/special/:id',
    name: 'Special',
    component: () => import('@/views/special/index'),
    meta: {
      title: '专题'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/Login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/Register'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/user/Forget'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/protocol/:type',
    name: 'Protocol',
    component: () => import('@/views/user/Protocol'),
    meta: {
      title: '协议'
    }
  },
  {
    path: '/newsdetail/:id',
    name: 'NewsDetail',
    component: () => import('@/views/news/NewsDetail'),
    meta: {
      title: '资讯详情'
    }
  },
  {
    path: '/newslist/:specId/:typeId',
    name: 'NewsList',
    component: () => import('@/views/news/NewsList'),
    meta: {
      title: '资讯列表'
    }
  },
  {
    path: '/user',
    name:'User',
    component:() => import('@/views/user/index'),
    meta:{
      title:'个人中心'
    },
    children:[
      {
        path: '/user/mycourse',
        name: 'MyCourse',
        component: () => import('@/views/user/center/MyCourse'),
        meta: {
          title: '我的课程'
        }
      },
      {
        path: '/user/myorder',
        name: 'MyOrder',
        component: () => import('@/views/user/center/MyOrder'),
        meta: {
          title: '我的订单'
        }
      },
      {
        path: '/user/myexam',
        name: 'MyExam',
        component: () => import('@/views/user/center/MyExam'),
        meta: {
          title: '我的题库'
        }
      },
      {
        path: '/user/mycollect',
        name: 'MyCollect',
        component: () => import('@/views/user/center/MyCollect'),
        meta: {
          title: '我的收藏'
        }
      },
      {
        path: '/user/mywrong',
        name: 'MyWrong',
        component: () => import('@/views/user/center/MyWrong'),
        meta: {
          title: '我的错题'
        }
      },
      {
        path: '/user/myinfo',
        name: 'MyInfo',
        component: () => import('@/views/user/center/MyInfo'),
        meta: {
          title: '我的信息'
        }
      },
      {
        path: '/user/myquestion',
        name: 'MyQuestion',
        component: () => import('@/views/user/center/MyQuestion'),
        meta: {
          title: '答疑服务'
        }
      },
    ]
  },
  {
    path: '/agreement/:type/:productid/:agreementid',
    name: 'Agreement',
    props: true,
    component: () => import('@/views/user/Agreement'),
    meta: { title: '课程协议' }
  },
  {
    path: '/order/:productid/:type',
    name: 'Order',
    component: () => import('@/views/order/index'),
    props: true,
    meta: {
      title: '创建订单'
    }
  },
  {
    path: '/orderPay/:orderid',
    name: 'OrderPay',
    component: () => import('@/views/order/OrderPay'),
    props: true,
    meta: {
      title: '订单支付'
    }
  },
  {
    path: '/orderalipay',
    name: 'OrderAlipay',
    component: () => import('@/views/order/OrderAlipay'),
    props: true,
    meta: {
      title: '支付宝支付'
    }
  },
  {
    path: '/paysuccess',
    name: 'PaySuccess',
    component: () => import('@/views/order/PaySuccess'),
    props: true,
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/user/progress/:gradeId',
    name: 'UserProgress',
    component: () => import('@/views/user/progress'),
    props: true,
    meta: {
      title: '课程观看进度'
    }
  },
	{
	  path: '/advert',
	  name: 'Advert',
	  component: () => import('@/views/advert/index'),
	  props: true,
	  meta: {
	    title: '广告'
	  }
	},
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

//路由守卫
router.beforeEach((to, from, next) => {
  let token = getLocalStorage('token');
  //console.log(from)
  if (to.path == '/login' || to.path == '/register') {
    if(from.path != '/forget' && from.path != '/'){
      setSessionStorage("previousRoute", router.currentRoute._value.fullPath);
    }
  }
  //网页标题
  if (to.meta.title) {
      if (getLocalStorage('showSiteInfo')) { //只有显示相关信息才改变标题
        document.title = getLocalStorage('siteInfo').info.sitename + '-' + to.meta.title;
      } else {
        document.title = to.meta.title;
      }
  }
  next();
});

export default router
