import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
export function getSiteInfo(data) {
  return request({
    url: '/index/siteinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询网站友情链接信息
 */
export function getLinksInfo(data){
  return request({
    url: '/index/blogroll',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询B端用户的首页模板,此方法为异步方法，不能使用
 */
export function getMerchantTemplate(data) {
  return request({
    url: '/index/templet',
    method: 'post',
    data: qs.stringify(data)
  })
}

//获取用户信息
export function getUserInfo() {
  return request(
    {
      url: '/user/userinfo',
      method: 'post',
    }
  )
}