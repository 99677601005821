<template>
  <router-view/>
  <el-dialog
    v-model="centerDialogVisible"
    title="提示"
    destroy-on-close
    center
    width="100%"
  >
		<div>
      <strong>检测到您正在使用移动设备浏览站点，是否要切换到移动设备站点？</strong>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">否</el-button>
        <el-button type="primary" @click="redirect">是</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import {getLocalStorage} from '@/utils/auth'

export default{
  data(){
    return {
      siteData:null,
      centerDialogVisible:false
    }
  },
  mounted(){
    this.getSiteWebInfo()
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getSiteWebInfo(){
				this.siteData = getLocalStorage('siteInfo');
				console.log('this.siteData');
        this.redirectMobile()
    },
    redirectMobile(){
      //判断是否手机端设备，如果是，并且设置了移动站点网址，则跳转。
      if(this.isMobile() && this.siteData.info.mdomain){
        this.centerDialogVisible = true;
      }else{
        this.centerDialogVisible = false;
      }
    },
    redirect(){
      if(getLocalStorage('showSiteInfo')){
        window.location.href = this.siteData.info.mdomain;
      }else{
        window.location.href = this.siteData.info.mdomain2;
      }
    }
  }
}
</script>
<style lang="scss">
	
</style>
